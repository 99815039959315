import React from "react";
import { socialMediaList } from "../../Data";

export default function Footer() {
    return (
        <footer className="hidden md:block w-full text-sm py-2 shadow-modal">
            <div className="flex flex-row items-center justify-between m-auto max-w-[968px] px-6 text-primary">
            <span className="font-semibold text-sm">&copy;2024 Damai Y.S</span>
            <div className="flex flex-row h-full items-center">
                {socialMediaList.map((socialMedia) => (
                    <i key={socialMedia.label} className={`uil uil-${socialMedia.icon} text-primary text-2xl cursor-pointer hover:-translate-y-2 duration-300 w-9`} onClick={() => window.open(socialMedia.link)}></i>  
                ))}
            </div>
            </div>
        </footer>
    )
}