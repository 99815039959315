export const education = [
    {
        label: "AWS Academy Cloud Developing",
        role: "Amazon Web Services - Digitalent",
        time: "May 24 - June 24",
        link: "https://www.credly.com/badges/36da1601-351e-4328-9d47-9745a06c2190/linked_in_profile",
    },
    {
        label: "Banking IT - Back End",
        role: "Digitalent - Rakamin Academy",
        time: "July 21 - Oct 21",
        link: "https://drive.google.com/file/d/11htxd_BreS2XVI47OcgSNGV9xXewCe5q/view",
    },
    {
        label: "Backend Development",
        role: "Glints Academy powered by Binar Academy",
        time: "Dec 20 - March 21",
        link: "https://drive.google.com/file/d/1ixboKC7K6VyvMxSQ2SzZOHd8Srp6-WY3/view",
    },
    {
        label: "Economics",
        role: "Andalas University",
        time: "Aug 12 - Jan 18",
        link: "https://pddikti.kemdikbud.go.id/detail-mahasiswa/RvUu2zmzvn2EYgQ1v_FrvTWc9c7tIOZUcvmtDaa6CIvghj5r546eAW0x4v6TC2HwZ-ouRA=="
    },
];

export const projects = [
    {
        label: "Forest Technologies",
        role: "Frontend Developer",
        time: "June 23 - Aug 23",
        image: "/project/forest.webp",
        desc: "B2B Tech and UI/UX Design Experts with 50+ successful projects delivered.",
        responsibilities: [
            "Developed and implemented engaging user interfaces using Typescript and Next.js.",
            "Ensured that web applications were responsive and worked seamlessly across various devices and screen sizes using Tailwind CSS.",
            "Integrate metadata tags for SEO to enhance visibility and click-through rates in search results.",
        ],
        tech: ["Next.Js", "Typescript", "TailwindCSS", "Responsive Web", "Material UI"],
        platform: {
            website: "https://theforest.ai/",
            linkedin: "https://www.linkedin.com/company/forest-technologies",
            instagram: "https://www.instagram.com/forest.technologies/",
        }
    },
    {
        label: "Canlah",
        role: "Fullstack Developer",
        time: "Nov 22 - May 23",
        image: "/project/canlah.webp",
        desc: "The platform aims to make e-commerce accessible and affordable, offering features that streamline the process of setting up and managing an online store.",
        responsibilities: [
            "Developed user interfaces using Typescript, React, and Grommet.",
            "Ensured that web applications were responsive and worked on mobile and desktop.",
            "Debugging and troubleshooting issues.",
            "Refactor code to improve code readability and reduce complexity."
        ],
        tech: ["Go", "Fiber", "MongoDB", "JWT", "React", "Redux", "Typescript", "Grommet.io", "Responsive Web"],
        platform: {
            website: "https://store.canlah.com/",
            linkedin: "https://www.linkedin.com/company/canlah",
        }
    },
    {
        label: "Luna",
        role: "Fullstack Developer",
        time: "July 22 - Dec 22",
        image: "/project/luna.webp",
        desc: "Luna is a platform that offers features for roadmap synchronization, milestone tracking, and risk management, which streamline the launch process and reduce the administrative overhead.",
        responsibilities: [
            "Implemented new feature requests from stakeholders.",
            "Built reusable components and pages using Angular and TailwindCss.",
            "Wrote server-side logic and optimized database queries using Node.js and MongoDB.",
            "Managed user roles and permissions.",
            "Developed API integration on the client side."
        ],
        tech: ["Node.Js", "MongoDB", "Angular", "TailwindCSS", "Typescript"],
        platform: {
            website: "https://www.withluna.ai/",
            linkedin: "https://www.linkedin.com/company/withluna-ai/"
        }
    },
    {
        label: "SquareArt",
        role: "Backend Developer",
        time: "May 22 - June 22",
        image: "/project/squareart.webp",
        desc: "SquareArt is an art marketplace that offers a diverse selection of original artwork from artists worldwide.",
        responsibilities: [
            "Wrote and performed unit tests.",
            "Managed data transformations and pipelines using gRPC and MongoDB."
        ],
        tech: ["Go", "MongoDB", "gRPC", "Debugging", "Unit Testing"],
        platform: {
            website: "https://squareart.exchange/",
            instagram: "https://www.instagram.com/squareart.exchange/",
        }
    },
    {
        label: "Maple",
        role: "Frontend Developer",
        time: "Jan 22 - July 22",
        image: "/project/maple.webp",
        desc: "Maple Haus is a homeowners' community platform. You can share ideas, inspirations, decoration tips, renovation journeys, and more.",
        responsibilities: [
            "Developed and engaged user interfaces using Angular and TailwindCss.",
            "Created reusable components to enhance code.",
            "Ensured that the web was responsive and worked seamlessly on desktop and mobile.",
            "Work closely with the design and backend development teams to ensure seamless integration APIs."
        ],
        tech: ["Angular", "TailwindCSS", "Typescript", "PrimeNG", "Firebase", "Responsive Web"],
        platform: {
            website: "https://www.maple.haus/",
            linkedin: "https://www.linkedin.com/company/maple-haus",
            instagram: "https://www.instagram.com/maple.haus/",
        }
    },
    {
        label: "Farmz",
        role: "Fullstack Developer",
        time: "Aug 21 - Dec 21",
        image: "/project/farmz.webp",
        desc: "Farmz Asia is focused on food education to help people understand more about food, nutrition, and healthy eating.",
        responsibilities: [
            "Maintained and implemented GraphQL API on the server side.",
            "Refactored code to improve code readability and reduce complexity.",
            "Updated and maintained user interfaces using Typescript and Angular.",
            "Monitoring performance and fixing errors reported via Sentry.",
            "Implemented new feature requests from stakeholders on both sides.",
        ],
        tech: ["Angular", "Typescript", "Firebase", "Go", "Gorilla Mux", "MongodB", "GraphQL", "Sentry"],
        platform: {
            website: "https://www.farmzasia.com/",
            linkedin: "https://www.linkedin.com/company/farmz-asia",
            instagram: "https://www.instagram.com/farmz_asia/",
        }
    },
];