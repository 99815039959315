import React, { useState } from "react";
import LineStepper from "../../Components/LineStepper";
import { Player } from "@lottiefiles/react-lottie-player";
import rocket from "./rocket.json"; 
import graduate from "./graduate.json";
import { SocialMediaType, socialMediaTypeMap } from "../../Data";

import { Pagination, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/pagination';
import { education, projects } from "./data";
import { snake } from "radash";

interface ProjectData {
    [key: string]: string;
}

export default function Journey() {
    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState(0);
    
    return (
        <>
        <section id="qualification" className="pt-8 pb-16 qualification" data-aos="fade-up">
            <h2 className="text-center font-semibold text-2xl md:text-4xl text-title mb-8">Personal Journey</h2>
            <div className="flex flex-col gap-3 mx-6 text-justify">
                <div>I am a graduate in economics who has a strong foundation in economic theory, analysis, and research. As I engaged in discussions about the developments in the world during the COVID-19 pandemic, I realized that information technology was rapidly advancing and exerting a significant influence across various sectors.</div>
                <div>Delving deeper into this field, I discovered a newfound passion for technology and its transformative potential in diverse industries. Recognizing the increasing demand for IT skills, I decided to switch my career in late 2020 and took several bootcamps to support my skills for it. By mid-2021, I joined a company in Singapore, <span className="font-semibold cursor-pointer hover:text-primary" onClick={() => window.open("https://theforest.ai/",)}>Forest Ai Pte. Ltd</span>, as a professional software engineer and was involved in several projects.</div>
            </div>
            <div className="flex flex-row items-center">
                <LineStepper data={education} />
                <Player src={graduate} autoplay loop className="w-fit hidden md:block" />
            </div>
            <div className="flex flex-row items-end">
                <Player src={rocket} autoplay loop className="h-full w-fit animate-fly -translate-y-full duration-500 hidden md:block" />
                <LineStepper data={projects} callback={(label) => {
                    setOpen(true)
                    const index = projects.findIndex((val: any) => val.label === label)
                    setDataModal(index)
                }} />
            </div>
        </section>
        {open && (
            <div className="h-screen w-screen bg-black/70 fixed z-[100] top-0 left-0 flex items-center justify-center">
                <i className="uil uil-times absolute right-2 md:right-10 text-[40px] top-10 cursor-pointer z-[10]
                    text-white" onClick={() => setOpen(!open)} />
                <Swiper
                    slidesPerView={1}
                    initialSlide={dataModal}
                    className="w-[730px]"
                    wrapperClass="snap-mandatory"
                    loop
                    effect={'coverflow'}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                        bulletClass: 'swiper-pagination-bullet opacity-8 bg-gray',
                        bulletActiveClass: 'swiper-pagination-bullet-active bg-blue opacity-full',
                    }}
                    keyboard
                    modules={[Pagination, Keyboard]}
                >
                    {projects.map((data) => (
                        <SwiperSlide key={data.label} className="!h-auto px-6 mb-10">
                            <div className="flex flex-col h-full justify-center gap-3 p-6 md:p-8 bg-mono rounded-2xl md:rounded-3xl text-letter">
                                <img onClick={() => window.open(data.image)} src={data.image} alt="company" className="w-full h-auto shadow-modal object-contain rounded-md md:rounded-xl" />
                                <div className="flex flex-row justify-between items-center">
                                    <div className="text-base md:text-xl text-left font-semibold">{data.label}</div>
                                    <div className="flex flex-row gap-2">
                                        {Object.entries(data.platform as ProjectData).map(([key, value]) => {
                                            const k = key as SocialMediaType;
                                            return (
                                                <i key={key} className={`uil uil-${socialMediaTypeMap[k]} text-primary text-2xl cursor-pointer`} onClick={() => window.open(value)} />
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="text-sm">{data.desc}</div>
                                <div className="text-sm font-bold">
                                    Responsibilities:
                                    <ul className="list-disc list-outside font-normal">
                                        {data.responsibilities.map((charge) => (
                                            <li>{charge}</li>
                                        ))}
                                    </ul>
                                    {(data.label === "Canlah" || data.label === "Maple") && (
                                        <div className="mt-2 text-sm font-semibold">Note: The server status is unknown. You can see more <a className="text-primary" href={`https://theforest.ai/work/${snake(data.label)}`}>here</a></div>
                                    )}
                                </div>
                                <div className="flex flex-row gap-2 flex-wrap items-center mt-2">
                                    {data.tech.map((tech: any) => (
                                        <div key={tech} className="px-2 py-1 bg-primary rounded-full text-xs text-white">{tech}</div>
                                    ))}
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        )}
        </>
    )
}